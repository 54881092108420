@import 'tailwindcss/utilities';
@import "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";
@import 'tailwindcss/base';
@import 'tailwindcss/components';



:root {
    --white: rgb(238, 234, 234);
    --blue: #3f51b5;
    --dark-blue: #001D3D;
    --black: rgb(0, 0, 0);
    --pink: #e6605c;
    --grey: #9e9e9e;
    --orange:#fc130f;
    --blue:#3f51b5
  }
  .warning-button {
    background-color:var(--orange);/* This is $my-orange  from theme - may inject at bootstrap*/
    color: white ;
  }
  
  .warning-button:hover {
    background-color: darken(#fc1c18, 5%); /* Slightly darker on hover */
  }
  
  .warning-button:focus {
    background-color: darken(#fc1c18, 5%); 
  }
  
  .warning-button:active {
    background-color: darken(#fc1c18, 10%); 
  }
  

.red-rectangle {
    position: relative;
    display:inline-block;
    width: 300px; 
    height: 50px; 
    transform:  translate(-50%, -50%) skew(-10deg);
    width: 200px;
  
}
body {
    
    background-color: white;
}

.mat-table {
    background-color: var(--grey)
  }
    .mat-row {
    background-color: var(--grey)
  }
  
  .mat-mdc-dialog-container .mdc-dialog__surface {
   background-color: transparent !important;
   box-shadow: none !important;

}
  .mat-header-row {
    background-color: var(--grey)
  }


  .mat-header-cell {
    background-color: var(--grey)
  }
  .mat-mdc-cell {
    color:var(--black)
  }
  .mat-dialog-container {
    border:2px solod purple;
    background-color: transparent !important; 
    box-shadow: none; 
  }

  button.mat-raised-button.custom-button {
    color: white;
    padding: 2rem 3rem; 
    border-radius: 0.375rem; 
    margin-bottom: 1rem; 
  }
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

.material-icons{
    font-family: 'Material Icons' 
}

.custom-dialog-container .mat-dialog-container {
  background-color: transparent; 
  box-shadow: none; 
  padding: 0;
}




.relative-position {
  position: relative;
}

/* Default styles */
.blob-1::before, .blob-2::before, .blob-3::before, .blob-4::before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.blob-1::before {
  background-image: url(/assets/blobs-blue/blob-1.svg);
  width: 15vw;
  height: 15vw;
  top: 80px;
  right: -250px;
  transform: rotate(75deg);
  z-index: 1;
}

.blob-2::before {
  background-image: url(/assets/blobs-blue/blob-2.svg);
  width: 35vw;
  height: 35vw;
  top: 550px;
  left: -450px;
  transform: rotate(30deg);
  z-index: 1;
}

.blob-3::before {
  background-image: url(/assets/blobs-blue/blob-3.svg);
  width: 25vw;
  height: 25vw;
  bottom: -750px;
  right: -250px;
  transform: rotate(75deg);
  z-index: 10;
}

.blob-4::before {
  background-image: url(/assets/blob-svgs/blob-blue-ghost.svg);
  width: 25vw;
  height: 25vw;
  top: 200px;
  left: -250px;
  transform: rotate(45deg);
  z-index: 10;
}

/* Container to center the image */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center the image vertically */
}

/* Spinning bot */
.spinning-bot {
 
  height: auto;
  animation: spin 1.5s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 1500px) {
  .blob-1::before {
    top: -1vw;
    right: -10vw;
    width: 20vw;
    height: 20vw;
    border:2px solid orange;
    overflow:  hidden;

  }
  .blob-2::before {
    top: 20vw;
    left: -20vw;
    width: 30vw;
    height: 30vw;
  }

  .blob-3::before {
    bottom: -50vw;
    right: -5vw;
    width: 20vw;
    height: 20vw;
  }

  .blob-4::before {
    top: 10vw;
    left: -5vw;
    width: 20vw;
    height: 20vw;
  }
}

/* tablet  */
@media (max-width: 1200px) {
  .blob-1::before {
    top: 70px;
    right: 10px;
    width: 20vw;
    height: 20vw;
  }

  .blob-2::before {
    top: 500px;
    left: -200px;
    width: 30vw;
    height: 30vw;
  }

  .blob-3::before {
    bottom: -700px;
    right: -200px;
    width: 20vw;
    height: 20vw;
  }

  .blob-4::before {
    top: 180px;
    left: -200px;
    width: 20vw;
    height: 20vw;
  }
}

/* Breakpoint at 1000px */
/*  small ipad */
@media (max-width: 1199px) {
  .blob-1::before, .blob-2::before, .blob-3::before, .blob-4::before {
    display:none;
  }
}
  



@media (max-width: 640px) {
  .container {
      flex-direction: column-reverse;
      align-items: center;
    }

    body h1 {
      font-size: 3.75rem; /* More specific selector */
    }
    body h2 {
      font-size: 1.2rem; /* More specific selector */
    }
  }
    
    
    